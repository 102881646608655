import { useState } from "react"
import styled from "styled-components"

import { useQueryClient } from "@tanstack/react-query"
import { AxiosError, AxiosResponse, Method as AxiosMethod } from "axios"
import { useSnackbar } from "notistack"

import { EditField, EditFieldBox } from "src/components/Settings/EditField"
import { ToggleSection } from "src/components/Settings/ToggleSection"
import {
  EditType,
  ISectionContents,
  TEditField,
  TSaveValue,
} from "src/data/editField/editFieldTypes"
import { homeKeys } from "src/data/homes/queries/homeQueryCache"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useTranslate } from "src/i18n/useTranslate"
import { MButtonLegacy } from "src/ui/Button/MButtonLegacy"
import { recommendedGray } from "src/ui/colors"
import { Heading2Mixin, Heading3Mixin } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { chunk, sleep } from "src/utils/genericUtil"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

const MAX_RECURSION_LEVEL = 1

export interface ISettingsViewConfig<S, R> {
  onSubmitSuccess?: (response: R, cacheKey?: string) => void | Promise<void>
  successToast?: string
  onSubmitFailure?: (setting: S, error: AxiosError) => void
  defaultCallMethod?: AxiosMethod
  subtitle?: string | JSX.Element
  hideDefaultError?: boolean
  loading?: boolean
}

export function SettingsView<S, P, Response = S>({
  title,
  subtitle,
  content,
  currentSettings,
  depth = 0,
  viewConfig,
  loading,
}: {
  title: string
  subtitle?: string | React.ReactNode
  content: ISectionContents<S, P>[]
  currentSettings: S[]
  depth?: number
  viewConfig?: ISettingsViewConfig<S, Response>
  loading?: boolean
}) {
  const { orgId } = useOrganization()
  // TODO WEB-387: We should perhaps refactor this component to separate the
  // recursive parts from the fixed parts
  const { _t } = useTranslate()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const [pending, setPending] = useState(false)
  const isBulkMode = currentSettings.length > 1

  function defaultErrorNotification() {
    enqueueSnackbar(`Error saving change`, {
      variant: "error",
      persist: true,
      action: (key) => (
        <MButtonLegacy
          onClick={() => closeSnackbar(key)}
          variant="text"
          color="default"
        >
          Dismiss
        </MButtonLegacy>
      ),
    })
  }

  /**
   * This global submit callback will be called within each config field; they
   * will in turn check the result of the save operation and act accordingly.
   */
  async function submit(
    value: TSaveValue,
    editField: TEditField<S, P>
  ): Promise<void> {
    setPending(true)

    try {
      const calls = currentSettings
        .map((setting) => async () => {
          try {
            if (editField.onSave) {
              await editField.onSave(value)
            } else {
              const url = editField.endpointUrl(setting)
              const response = await minutApiHttpClient({
                data: editField.payload(value, setting),
                method:
                  editField.endpointMethod ||
                  viewConfig?.defaultCallMethod ||
                  "patch",
                url,
              })
              if (viewConfig?.onSubmitSuccess) {
                await viewConfig.onSubmitSuccess(response.data, url)
              }
              return response
            }
          } catch (e) {
            const err = e as AxiosError
            viewConfig?.onSubmitFailure?.(setting, err)
            throw err
          }
        })
        .filter((c) => !!c)

      const chunkedCalls = getChunkedCalls(calls, 5)
      for (let i = 0; i < chunkedCalls.length; i++) {
        await Promise.all(chunkedCalls[i].map((call) => call()))
        if (i < chunkedCalls.length - 1) {
          await sleep(1050) // wait a bit more than 1 second after all chunks except last
        }
      }

      if (editField.affectsHomes) {
        queryClient.invalidateQueries(homeKeys.orgHomes(orgId))
      }

      enqueueSnackbar(viewConfig?.successToast || `Update successful`)
    } catch (error) {
      !viewConfig?.hideDefaultError && defaultErrorNotification()
      throw error
    } finally {
      setPending(false)
    }
  }

  if (depth > MAX_RECURSION_LEVEL) {
    throw Error(`Recursion level at ${depth}; rethink your design!`)
  }

  const sectionContent = content.map((c, index) => {
    switch (c.type) {
      case EditType.SECTION: {
        const _title = typeof c.title === "function" ? c.title(_t) : _t(c.title)
        const _description =
          typeof c.description === "function"
            ? c.description(_t)
            : !!c.description
              ? _t(c.description)
              : undefined

        return (
          <SettingsView
            key={`${depth}-${index}-${c.type}`}
            title={_title}
            subtitle={_description}
            depth={depth + 1}
            content={c.contents}
            currentSettings={currentSettings}
            viewConfig={viewConfig}
            loading={pending || loading}
          />
        )
      }
      case EditType.SECTION_TOGGLE:
        return isBulkMode && c.hideInBulkMode ? null : (
          <ToggleSection
            key={`${depth}-${index}-${c.type}-${c.toggle.storedValue}`}
            storedSettings={currentSettings}
            subSection={c}
            loading={pending || !!loading}
            onSave={submit}
          />
        )
      default:
        if (c.type === EditType.NODE) {
          const deviceSettings = currentSettings[0]

          return (isBulkMode && c?.hideInBulkMode) ||
            (c.filterOutNode && c.filterOutNode(deviceSettings)) ? null : (
            <EditFieldBox key={c.key}>
              {c.contents(deviceSettings)}
            </EditFieldBox>
          )
        }

        return isBulkMode && c?.hideInBulkMode ? null : (
          <EditField
            key={`${depth}-${index}-${c.type}`}
            storedSettings={currentSettings}
            loading={pending || !!loading}
            fieldData={c}
            submit={submit}
          />
        )
    }
  })

  return (
    <>
      {!!title && (
        <SectionTitle $depth={depth} as={depth === 0 ? "h2" : "h3"}>
          {title}
        </SectionTitle>
      )}
      {!!subtitle && <SectionDescription>{subtitle}</SectionDescription>}
      {sectionContent}
    </>
  )
}

const SectionTitle = styled.h2<{ $depth: number }>`
  ${({ $depth }) => ($depth === 0 ? Heading2Mixin : Heading3Mixin)};
  margin: ${({ $depth }) =>
    $depth > 0 ? `${spacing.XL3} 0 ${spacing.XS} 0` : `0 0 0 0`};
`

const SectionDescription = styled.div`
  color: ${recommendedGray};
`

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
type TDeferredApiCall = () => Promise<AxiosResponse<any> | undefined>
function getChunkedCalls(
  arr: TDeferredApiCall[],
  chunkSize: number
): TDeferredApiCall[][] {
  return chunk(arr, chunkSize)
}
